
















































































































































@import '../../utilities/config';
.m-select-accred-form {
  &__title {
    margin-top: 0;
    margin-bottom: 16px;
    font-weight: 500; //font-weight: 400;;
  }
  &__suptitle {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 300;

    &--margin-bottom {
      margin-bottom: 16px;
    }
  }
  &__text {
    font-size: 14px;
  }
  &__logo-link {
    display: block;
    margin-bottom: 24px;
  }
  &__btns {
    margin-top: 35px;
  }
  &__row {
    margin-top: 32px;
  }
  &__submit-col {
    padding-top: 16px;
    padding-bottom: 32px;
    text-align: right;
    @media screen and (min-width: breakpoint-min('md')) {
      padding-top: 2px;
      text-align: left;
    }
  }
  &__error {
    font-weight: 400;
    color: theme-color('danger');
  }
}
